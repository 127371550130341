import * as Cookies from 'js-cookie';

export default {
  created() {
    if (
      Cookies.get('referrer') === undefined &&
      document.referrer.indexOf('account.google.com') === -1 &&
      document.referrer.indexOf(window.location.host) === -1
    ) {
      Cookies.set('referrer', document.referrer);
    }
  },
};
